import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllCustomers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    updateCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateCustomer`, customerData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getDepLabData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getDepLabData')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    exportExcelCustomer(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportExcelCustomer',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    
  },
};
