<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" >Customer Edit</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="customerRules">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Contact" label-for="contact">
                <validation-provider name="Name" #default="{ errors }" rules="required">
                  <b-form-input id="contact" type="text" v-model="userInfo.contact"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="contactEmail">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="contactEmail" type="text"  v-model="userInfo.contactEmail"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

              <!-- Surname -->
              <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Department" label-for="surname">
                  <b-form-input id="department" type="text" v-model="userInfo.department"></b-form-input>
              </b-form-group>
            </b-col>

              <!-- Surname -->
              <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Lab" label-for="lab">
                  <b-form-input id="lab" type="text" v-model="userInfo.lab"></b-form-input>
              </b-form-group>
            </b-col>


              <!-- Surname -->
              <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Phone" label-for="contactPhone">
                  <b-form-input id="contactPhone" type="text" v-model="userInfo.contactPhone"></b-form-input>
              </b-form-group>
            </b-col>

            
              <!-- Surname -->
              <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Address" label-for="companyAddress">
                  <b-form-input id="companyAddress" type="text" v-model="userInfo.companyAddress"></b-form-input>
              </b-form-group>
            </b-col>
            </b-row>
            <b-row class="mt-3">

              <b-col>
                <h4>Job Numbers</h4>
                <b-card class="d-flex justify-content-center align-items-center">
                  <b-badge v-for="id in userInfo.jobIds" :key="id" pill
          variant="success" class="p-1 m-1" @click="navigateProject(id)"> {{ id }}</b-badge>
                </b-card>
              </b-col>
            </b-row>


         

           
      
            <b-row>
            <b-col cols="12">
         
              <b-button type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import customerStoreModule from '../customerStoreModule';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'customer';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  props: {
    userInfo: {
      type: Object,
      requried: true,
    },
  },


  data: function() {
    var customerValue = {
      contact: null,
      contactEmail: null,
      contactPhone: null,
      department: null,
      lab: null,
      companyAddress: null,
      jobIds : [],
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
    ];

    return {
      customerValue: customerValue,
      baseURL: store.state.app.baseURL,
      selectedImage: null,
      required,
      email,
      roleOptions: [
        {
          id: 1,
          name: 'manager',
          permissions: [],
        },
        {
          id: 2,
          name: 'customer',
          permissions: [],
        },
      ],
      statusOptions,
      customerId: null,
      formShow: false,
    };
  },

  created() {
  },
  mounted() {
    // Handle the case when props are undefined (e.g., page refresh)
    if (!this.userInfo) {
      // Optionally, you can redirect the user to another route
      this.$router.push({ name: 'customer-list' });

 
    }
    else{
      this.customerValue = Object.assign({}, this.userInfo);
    }
  },

  methods: {

    
    navigateProject(id) {
      this.loading = false;
      const routeUrl = router.resolve({ name: 'project-preview', params: { id: id } }).href;
      window.open(routeUrl, '_blank');   
     },

   

    formSubmitted() {
      this.formShow = true;
      this.$refs.customerRules.validate().then((success) => {
        if (success) {
            store
              .dispatch('customer/updateCustomer', {userInfo:this.userInfo,customerValue:this.customerValue})
              .then((response) => {
                if (response.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Customer Update Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  setTimeout(function() {
                    this.formShow = false;
                    router.push({ name: 'customer-list' });
                  }, 100);
                }
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
